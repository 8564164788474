.container {
  width: 100%;
  padding: 0 0 9px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  position: relative;
  max-width: 400px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  &:before {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    top: 50%;
    margin-top: -13px;
    left: 10%;
    background-color: #E9ECEE
  }
}

.option {
  cursor: grabbing !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  width: 20%;
  margin-top: 9px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:active {
    .label {
      color: #313B3F !important
    }
    .icon {
      svg {
        path:first-child {
          fill: #8B959A
        }
      }
    }
  }
}

.current {
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  width: 20%;
  height: 55px;
  top: 0;
  left: 0;
  will-change: transform;
  //transition: transform .5s ease-in;
  cursor: grab;

  .svgWrapper{
    position: relative;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
    pointer-events: none;
    svg {
      position: absolute;
      width: 55px;
      height: 55px;
      top: 0;
      left: 0;
      will-change: transform;
    }
  }
  .touchMarker {
    bottom: -10px;
    left: 50%;
    margin-left: -5px
  }
}

.touchMarker {
  position: absolute;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 6px rgba(0, 0, 0, 0.06);
  -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  pointer-events: none;
  will-change: transform;
  bottom: 20px;
  left: 50%;
  margin-left: -18px;

  &.showTouch {
    transform: none;
    opacity: 1;
  }
}


.icon {
  width: 36px;
  height: 36px;
  will-change: transform;
  pointer-events: none;
  svg {
    path {
      fill: #fff;
    }
    path:first-child {
      fill: #ddd;
      transition: fill .1s ease-in-out
    }
  }
}

.label {
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  will-change: transform;
}

@media screen and (max-width: 500px) {
  .container {
    max-width: 300px
  }
}


.submitButton {
  color: #1B2126;
}
