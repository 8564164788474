.container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  .loadingIcon {
    top: 0;
    left: 0;
    font-size: 50px;

    svg {
      height: 100%;
      margin: auto;
    }
  }
}

.target {
  background: #F2F5F7;
}

.trafficButton {
  svg {
    margin-right: 5px;
    height: 100%;
  }
}

.start {
  svg {
    margin-right: 10px;
  }
}

.info {
  font-size: 21px;
  margin: 0;
  span {

    &:last-child {
      padding-left: 5px;
      color: #8D99AE
    }
  }
}