.container {
    left: 0;
    top: 0;
    z-index:1;
    width: 100%;
    height: 100%;
    background: white;

    header {
        h1 {
            &.fadeOut {
                animation: fadeInFromNone 0.5s ease-out;
            }
        }
    }
}

.innerContainer {
    width:100%;
}

@media only screen and (min-width:  765px) {
    .innerContainer {
        max-width: 75rem;
    }
}


.scrollContainer {
    z-index: 2;
}

.right {
    right: 0;
}

.searchButton {
    right: 0;
    position: absolute;
}

.search {
    overflow: hidden;
    height: 38px;
    width: 0;
    position: absolute;
    top: 23px;
    right: 52px;
    border-radius: 18px;
    background: #E6EAEF;
    transition: all 0.5s ease-out;
    display: flex;
    align-items: center;

    input {
        height: 100%;
        flex: 1;
        background: transparent;
        border: none;
        outline: none;
        margin-left: 5px;
    }

    &.active {
        width: calc(100% - 50px);
        padding: 0 10px;
    }
}

.backButton {
    background: #C2F1EA !important;
}

@keyframes fadeInFromNone {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.headerContainer {
    height: 85px;
    align-items: center;
    margin: 0 20px;
    h1 {
        font-size: 28px;
        margin: 0;
    }
}