.ui {
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.upperBlock {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.w-100-dynamic {
  width: 100%;
}

.upperBar {
  //position: relative;
  width: auto;
  height: auto;
}
.loadingContainer {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

@media only screen and (min-width: 765px) {
  .upperBlock {
    display: flex !important;
    flex-direction: row;
    align-items: start !important;
    width: 100% !important;
  }
  .upperBar {
    position: relative;
    width: 40%;
    max-width: 45rem;
    height: 100%;
  }
  .w-100-dynamic {
    width: 40%;
    max-width: 40rem;
  }
}
