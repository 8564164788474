.standardCheckbox {
  position: relative;
  height: 25px;
  width: 25px;
  border-radius: 7px;
  text-align: center;
  line-height: 25px;

  svg {
    display: none;
  }

  &--active {
    svg {
      display: inline;
    }
  }
}
