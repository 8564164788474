.button {
  position: relative;
  display: inline-block;
  height: 1.5rem;
  min-width: 2.5rem;
  margin: 0;
  //margin-right: 5px;
  //margin-top: 5px;
  //margin-bottom: 25px;
}

.slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5rem;
  -webkit-transition: .2s cubic-bezier(.55,0,.1,1);
  transition: .2s cubic-bezier(.55,0,.1,1);

  &:before {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    left: 0.2rem;
    top: 0.2rem;
    border-radius: 50%;
    -webkit-transition: .2s cubic-bezier(.55,0,.1,1);
    transition: .2s cubic-bezier(.55,0,.1,1);
  }
}

