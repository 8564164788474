.container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 20px;
  margin-bottom: 20px;
}

.callNumberButton, .addToFavoriteButton {
  display: block;
  margin-bottom: 10px;
  border-radius: 50%;
  width: 38px;
  height: 38px;
}

.addToFavoriteButton {
  margin-bottom: 0;
  padding: 0;
}

.textContainer {
  padding-right: 25px;
}

.primaryTitle {
  margin-top: 15px;
  margin-bottom: 20px;
}

.secondaryTitle {
  margin-bottom: 20px;
}
