.most-card-titleinput {
  background: #f2f5f7 !important;
  border: none;
  box-shadow: none !important;

  &::placeholder {
    color: #8d99ae;
  }
}
.noBeforeAfter {
  &:before,
  &:after {
    content: "";
  }
}

.searchlabel {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  background-clip: padding-box;
}
.keyBoardPadding {
  padding-bottom: 50% !important;
}
.virtualKeyboard {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchIcon {
  color: #394854;
  font-size: 18px;
  top: 10px;
  left: 15px;
}

.mostCardTitle {
  min-width: 230px;
}

.GeoTitle {
  font-size: 28px;
  font-weight: 800;
}

.FilterContainer {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100px;
    pointer-events: none;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }
}
.FilterDetail {
  max-height: 17rem !important;
  overflow: auto;
}

.container {
  background: #fff;
  top: 0;
  overflow: auto;
  @media screen and (max-width: 765px) {
    margin-top: 1rem;
  }
}
.innerContainer {
}

.filterContainer {
  position: absolute;
  padding-left: 100px;
}

.filterDetailContainer {
  margin-top: 20px;
  header {
    display: flex;
    justify-content: space-between;
  }
}

.matchesCount {
  z-index: 1;
  right: 0;
  top: 10px;
}

.filterButton {
  background: #c2f1ea;
  border-color: #c2f1ea;
}

.AbsoluteFilterButton {
  position: absolute;
  left: -100px;
  top: 0;
}

.ShowAbsoluteFilterButton {
  left: 10px;
  animation-name: bump;
  animation-duration: 0.5s;
}

@keyframes bump {
  0% {
    left: -100px;
  }
  75% {
    left: 20px;
  }
  100% {
    left: 10px;
  }
}
