.floorInfoBackground {
  position: absolute;
  background: #fff;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.floorInfo {
  line-height: 38px;
  font-size: 20px;
  color: #394854;
  z-index: 1;
  position: relative;
}

.btn {
  &:disabled {
    background: transparent !important;
    color: #394854 !important;
  }
}

.next {
  border-radius: 0 5px 5px 0;
  border-left: 1px solid lightgrey;
}

.prev {
  border-radius: 5px 0 0 5px
}


.stepInfo {
  b {
    font-weight: 800;
    font-size: 21px;
  }

  span {
    font-weight: 700;
    font-size: 14px;
  }
}

.buttonContainer {
  padding: 0 30px;

  span {
    color: #394854;
    font-weight: 700;
    font-size: 14px;
  }
}

.openContent {
  b {
    font-weight: 700;
    color: #394854;
    font-size: 18px;
    display: block;
  }
}

.targetInformation {
  h4 {
    color: #394854;
    font-size: 21px;
    font-weight: 800;
    margin-bottom: 5px;
  }

  p {
    color: #394854;
    font-size: 14px;
  }
}

.settingTitle {
    color: #8D99AE;
  font-size: 14px;
  font-weight: 700;
}

.settingValue {
  color: #394854;
  font-size: 14px;
  font-weight: 700;
}

.close {
  background: #FA6D6D;
  color: #fff;
}

.AudioContainer {
  position: absolute;
  top: 20px;
  right: 20px;
}