.pulsing11 {
  animation-name: pulse11;
}

.pulsing13 {
  animation-name: pulse11;
}

.pulsing {
  animation-direction: alternate;
  animation-duration: 0.7s;
  animation-iteration-count: infinite
}

@keyframes pulse13 {
  0% {
    transform: none;
  }
  100% {
    transform: scale(1.3);
  }
}

@keyframes pulse11 {
  0% {
    transform: none;
  }
  100% {
    transform: scale(1.1);
  }
}

.triplePulse {
  animation-name: pulse13;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

}

.one {
  animation-delay: 0s;
}

.two {
  animation-delay: 0.33s;
}

.three {
  animation-delay: 0.66s;
}

