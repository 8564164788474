.container {
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  justify-content: center;
  width: 100% !important;
  height: 100% !important;
}
.content {
  width: 100% !important;
  height: 100% !important;
}
