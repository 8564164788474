.back {
  width: 46px;
  height: 38px;
}


.desktopHeaderContainer {
  min-width: 0;
}

@media only screen and (min-width: 765px) {
  .desktopHeaderContainer {
    min-width: 25rem;
  }
}

.settingsContainer {
  z-index: 20;
}

.buttonContainer {
  padding: 5px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 1px;
}