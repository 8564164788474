.relativeContainer {
  position: relative;
  height: 0;
  width: 100%;
  z-index: 10;
}

.absoluteContainer {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}