.container {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 1rem;

  &.open {
    border-radius: 0rem;

    header {
      padding-top: 60px;
      padding-bottom: 15px;
    }
  }
}

.sideMargins_activated {
  margin-left: 2.5%;
  margin-right: 2.5%;
}
.sideMargins_standard {
  margin-left: 0;
  margin-right: 0;
}

@media only screen and (min-width: 765px) {
  .sideMargins_activated,
  .sideMargins_standard {
    margin-left: 20%;
    margin-right: 20%;
  }
}

.roundedIconPosition {
  position: absolute;
  top: 0;
  right: 0.5rem;
}
.hidden {
  visibility: hidden;
}

.header {
  display: flex;
  z-index: 2;
  // min-height: 50px;

  section {
    transition: 0.5s;
    display: block;
    padding-top: 30px;

    img {
      display: inline-block;
    }
  }

  &.shadow {
    box-shadow: 0 4px 26px -2px grey;

    section {
      padding: 0 !important;
    }
  }
}

.dragHead {
  padding: inherit;
  left: 0;
  top: 0;
}

.showAll {
  background: #e6eaef !important;
  font-size: 14px !important;
}

.noShadow {
  box-shadow: none !important;
}

.closeButton {
  top: 50px;
  right: 10px;
  z-index: 1;
  color: #394854 !important;
}
