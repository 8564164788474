

.title {
  font-weight: 800;
  font-size: 28px;
}

.quitNavigation {
  position:absolute;
  top: 0;
  right: 0
}